// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/optimized/ivs-6804.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_hero__sD6OZ {
  min-height: 85vh;
  width: 100%;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url("../../assets/landing.webp"), no-repeat; */
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
  background-position: top;
  background-size: cover;
  padding: 3rem 4vw;
  padding-bottom: 5rem;
  margin-top: 100px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (max-width: 1012px) {
  .about_hero__sD6OZ {
    padding-top: 70rem;
  }
}

.about_hero__sD6OZ h3 {
  font-size: 2rem;
  color: #fff;
}

.about_hero__sD6OZ h1 {
  font-size: 7rem;
  color: #fff;
  font-weight: 700;
}

.about_desc__-QCTS {
  padding: 2rem 4vw;
}

.about_desc__-QCTS .about_head__HPTkQ h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--main-color);
}

.about_desc__-QCTS .about_content__CaW47 h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.about_moto_content__xVp4I {
  width: 100%;
  background-color: rgb(241, 200, 198);
  padding: 2rem 0;
}
.about_desc__-QCTS .about_moto_content__xVp4I h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  /* color: #fff; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/about/about.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX;kDACgD;EAChD;sDACwD;EACxD,wBAAwB;EACxB,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EAEtB,yBAAyB;AAC3B;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".hero {\n  min-height: 85vh;\n  width: 100%;\n  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),\n    url(\"../../assets/landing.webp\"), no-repeat; */\n  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),\n    url(\"../../assets/optimized/ivs-6804.webp\"), no-repeat;\n  background-position: top;\n  background-size: cover;\n  padding: 3rem 4vw;\n  padding-bottom: 5rem;\n  margin-top: 100px;\n  display: flex;\n  display: -ms-flexbox;\n  flex-direction: column;\n  -ms-flex-direction: column;\n  justify-content: flex-end;\n}\n\n@media screen and (max-width: 1012px) {\n  .hero {\n    padding-top: 70rem;\n  }\n}\n\n.hero h3 {\n  font-size: 2rem;\n  color: #fff;\n}\n\n.hero h1 {\n  font-size: 7rem;\n  color: #fff;\n  font-weight: 700;\n}\n\n.desc {\n  padding: 2rem 4vw;\n}\n\n.desc .head h2 {\n  font-size: 3rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n  color: var(--main-color);\n}\n\n.desc .content h4 {\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n}\n\n.moto_content {\n  width: 100%;\n  background-color: rgb(241, 200, 198);\n  padding: 2rem 0;\n}\n.desc .moto_content h4 {\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n  /* color: #fff; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `about_hero__sD6OZ`,
	"desc": `about_desc__-QCTS`,
	"head": `about_head__HPTkQ`,
	"content": `about_content__CaW47`,
	"moto_content": `about_moto_content__xVp4I`
};
export default ___CSS_LOADER_EXPORT___;
