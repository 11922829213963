// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 2rem 4vw;
  padding-bottom: 6rem;
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/program-grid/programgrid.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,SAAS;EACT,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".grid {\n  display: grid;\n  display: -ms-grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1rem;\n  padding: 2rem 4vw;\n  padding-bottom: 6rem;\n}\n\n@media screen and (max-width: 750px) {\n  .grid {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
