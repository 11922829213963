// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/core.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.values_hero__UNL2a {
    min-height: 85vh;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 3rem 4vw;
    padding-bottom: 5rem;
    margin-top: 100px;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: flex-end;
}

.values_hero__UNL2a h3 {
    font-size: 2rem;
    color: #fff;
}

.values_hero__UNL2a h1 {
    font-size: 7rem;
    color: #fff;
    font-weight: 700;
}

.values_desc__A04DP {
    padding: 4rem 4vw;
}

.values_desc__A04DP .values_head__5OeGh h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: var(--main-color);
}

.values_desc__A04DP .values_content__fERqN h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/our-values/values.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX;0DAC2C;IAC3C,+BAA+B;IAC/B,sBAAsB;IACtB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,aAAa;IACb,oBAAoB;IACpB,sBAAsB;IAEtB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".hero {\n    min-height: 85vh;\n    width: 100%;\n    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),\n        url(\"../../assets/core.jpg\"), no-repeat;\n    background-position: top center;\n    background-size: cover;\n    padding: 3rem 4vw;\n    padding-bottom: 5rem;\n    margin-top: 100px;\n    display: flex;\n    display: -ms-flexbox;\n    flex-direction: column;\n    -ms-flex-direction: column;\n    justify-content: flex-end;\n}\n\n.hero h3 {\n    font-size: 2rem;\n    color: #fff;\n}\n\n.hero h1 {\n    font-size: 7rem;\n    color: #fff;\n    font-weight: 700;\n}\n\n.desc {\n    padding: 4rem 4vw;\n}\n\n.desc .head h2 {\n    font-size: 3rem;\n    font-weight: 600;\n    margin-bottom: 2rem;\n    color: var(--main-color);\n}\n\n.desc .content h4 {\n    font-size: 1.8rem;\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `values_hero__UNL2a`,
	"desc": `values_desc__A04DP`,
	"head": `values_head__5OeGh`,
	"content": `values_content__fERqN`
};
export default ___CSS_LOADER_EXPORT___;
