// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/core.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.core_hero__Ci8GG {
  min-height: 85vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 3rem 4vw;
  padding-bottom: 5rem;
  margin-top: 100px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: flex-end;
}

.core_hero__Ci8GG h3 {
  font-size: 2rem;
  color: #fff;
}

.core_hero__Ci8GG h1 {
  font-size: 7rem;
  color: #fff;
  font-weight: 700;
}

.core_desc__J8oA0 {
  padding: 2rem 4vw;
}

.core_desc__J8oA0 .core_head__ajnmn h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--main-color);
}

.core_desc__J8oA0 .core_content__GVVEy h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1012px) {
  .core_hero__Ci8GG {
    padding-top: 70rem;
  }

  .core_hero__Ci8GG h1 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 976px) {
  .core_hero__Ci8GG h1 {
    font-size: 5rem;
  }

  .core_hero__Ci8GG {
    min-height: 25vh;
    justify-content: center;
    padding-top: 150px;
  }
}

@media screen and (max-width: 760px) {
  .core_hero__Ci8GG h1 {
    font-size: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/core-principle/core.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX;sDACyC;EACzC,+BAA+B;EAC/B,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EAEtB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".hero {\n  min-height: 85vh;\n  width: 100%;\n  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),\n    url(\"../../assets/core.jpg\"), no-repeat;\n  background-position: top center;\n  background-size: cover;\n  padding: 3rem 4vw;\n  padding-bottom: 5rem;\n  margin-top: 100px;\n  display: flex;\n  display: -ms-flexbox;\n  flex-direction: column;\n  -ms-flex-direction: column;\n  justify-content: flex-end;\n}\n\n.hero h3 {\n  font-size: 2rem;\n  color: #fff;\n}\n\n.hero h1 {\n  font-size: 7rem;\n  color: #fff;\n  font-weight: 700;\n}\n\n.desc {\n  padding: 2rem 4vw;\n}\n\n.desc .head h2 {\n  font-size: 3rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n  color: var(--main-color);\n}\n\n.desc .content h4 {\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n}\n\n@media screen and (max-width: 1012px) {\n  .hero {\n    padding-top: 70rem;\n  }\n\n  .hero h1 {\n    font-size: 6rem;\n  }\n}\n\n@media screen and (max-width: 976px) {\n  .hero h1 {\n    font-size: 5rem;\n  }\n\n  .hero {\n    min-height: 25vh;\n    justify-content: center;\n    padding-top: 150px;\n  }\n}\n\n@media screen and (max-width: 760px) {\n  .hero h1 {\n    font-size: 4rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `core_hero__Ci8GG`,
	"desc": `core_desc__J8oA0`,
	"head": `core_head__ajnmn`,
	"content": `core_content__GVVEy`
};
export default ___CSS_LOADER_EXPORT___;
