// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid_grid__2__46wKw {
  grid-template-columns: 1fr 1fr;
}

.grid_grid__3__v6LeX {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid_grid__4__CdUDx {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (ax-width: 1200px) {
  .grid_grid__4__CdUDx {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1050px) {
  .grid_grid__3__v6LeX,
  .grid_grid__4__CdUDx {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .grid_grid__3__v6LeX,
  .grid_grid__2__46wKw,
  .grid_grid__4__CdUDx {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/grid/grid.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE;IACE,kCAAkC;EACpC;AACF;;AAEA;EACE;;IAEE,8BAA8B;EAChC;AACF;;AAEA;EACE;;;IAGE,0BAA0B;EAC5B;AACF","sourcesContent":[".grid__2 {\n  grid-template-columns: 1fr 1fr;\n}\n\n.grid__3 {\n  grid-template-columns: 1fr 1fr 1fr;\n}\n\n.grid__4 {\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n\n@media screen and (ax-width: 1200px) {\n  .grid__4 {\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n}\n\n@media screen and (max-width: 1050px) {\n  .grid__3,\n  .grid__4 {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n@media screen and (max-width: 650px) {\n  .grid__3,\n  .grid__2,\n  .grid__4 {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid__2": `grid_grid__2__46wKw`,
	"grid__3": `grid_grid__3__v6LeX`,
	"grid__4": `grid_grid__4__CdUDx`
};
export default ___CSS_LOADER_EXPORT___;
