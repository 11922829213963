// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.card img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  -o-object-fit: cover;
}

.card .details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
  min-height: 130px;
  border-top-right-radius: 35px;
  background: var(--main-color);
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
}

.card .details h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.card .details h4 {
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.3;
}
`, "",{"version":3,"sources":["webpack://./src/components/card/card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;EAC7B,6BAA6B;EAC7B,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EAEtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".card {\n  position: relative;\n  width: 100%;\n  height: 450px;\n  overflow: hidden;\n  overflow: -moz-hidden-unscrollable;\n}\n\n.card img {\n  width: 100%;\n  height: 550px;\n  object-fit: cover;\n  -o-object-fit: cover;\n}\n\n.card .details {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding: 1rem 3rem;\n  min-height: 130px;\n  border-top-right-radius: 35px;\n  background: var(--main-color);\n  display: flex;\n  display: -ms-flexbox;\n  flex-direction: column;\n  -ms-flex-direction: column;\n  justify-content: center;\n}\n\n.card .details h2 {\n  color: #fff;\n  font-size: 3rem;\n  font-weight: 600;\n  margin-bottom: 0.5rem;\n}\n\n.card .details h4 {\n  color: #fff;\n  font-size: 1.6rem;\n  line-height: 1.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
