// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/faculty.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faculties_hero__ZZhjG {
  min-height: 90vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
  background-size: cover;
  background-position: top;
  padding: 3rem 4vw;
  padding-bottom: 5rem;
}

.faculties_hero__ZZhjG {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: flex-end;
}

.faculties_hero__ZZhjG h3 {
  font-size: 2rem;
  color: #fff;
}

.faculties_hero__ZZhjG h1 {
  font-size: 7rem;
  color: #fff;
  font-weight: 700;
}

.faculties_hero__ZZhjG p {
  font-size: 2rem;
  width: 800px;
  color: #fff;
}

.faculties_campuses__Vhg6l {
  padding: 3rem 4vw;
}

.faculties_faculties__arena__mBI8o {
    padding: 5rem 4vw;
}

.faculties_faculties__arena__mBI8o h2 {
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.2;
}

@media screen and (max-width: 1012px) {
  .faculties_hero__ZZhjG {
    padding-top: 70rem;
  }
}

@media screen and (max-width: 850px) {
  .faculties_hero__ZZhjG p {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/faculties/faculties.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX;sDAC4C;EAC5C,sBAAsB;EACtB,wBAAwB;EACxB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EAEtB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".hero {\n  min-height: 90vh;\n  width: 100%;\n  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),\n    url(\"../../assets/faculty.jpg\"), no-repeat;\n  background-size: cover;\n  background-position: top;\n  padding: 3rem 4vw;\n  padding-bottom: 5rem;\n}\n\n.hero {\n  display: flex;\n  display: -ms-flexbox;\n  flex-direction: column;\n  -ms-flex-direction: column;\n  justify-content: flex-end;\n}\n\n.hero h3 {\n  font-size: 2rem;\n  color: #fff;\n}\n\n.hero h1 {\n  font-size: 7rem;\n  color: #fff;\n  font-weight: 700;\n}\n\n.hero p {\n  font-size: 2rem;\n  width: 800px;\n  color: #fff;\n}\n\n.campuses {\n  padding: 3rem 4vw;\n}\n\n.faculties__arena {\n    padding: 5rem 4vw;\n}\n\n.faculties__arena h2 {\n    font-size: 3rem;\n    color: rgba(0, 0, 0, 0.8);\n    line-height: 1.2;\n}\n\n@media screen and (max-width: 1012px) {\n  .hero {\n    padding-top: 70rem;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .hero p {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `faculties_hero__ZZhjG`,
	"campuses": `faculties_campuses__Vhg6l`,
	"faculties__arena": `faculties_faculties__arena__mBI8o`
};
export default ___CSS_LOADER_EXPORT___;
