// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    overflow: hidden;
    width: 100%;
}

.text-slider {
    display: flex;
    animation: slide 50s linear infinite;
}

.text {
    flex: 0 0 auto;
    white-space: nowrap;
    font-size: 15px;
    color: white;
    margin: 0 50px;
    animation: pulse 2s ease-in-out infinite alternate;
}

.text:hover {
    animation-play-state: paused;
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(1.1);
        opacity: 0.7;
    }
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TextSlider/Slider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,cAAc;IACd,kDAAkD;AACtD;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;;IAEA;QACI,qBAAqB;QACrB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".slider-container {\n    overflow: hidden;\n    width: 100%;\n}\n\n.text-slider {\n    display: flex;\n    animation: slide 50s linear infinite;\n}\n\n.text {\n    flex: 0 0 auto;\n    white-space: nowrap;\n    font-size: 15px;\n    color: white;\n    margin: 0 50px;\n    animation: pulse 2s ease-in-out infinite alternate;\n}\n\n.text:hover {\n    animation-play-state: paused;\n}\n\n@keyframes pulse {\n    from {\n        transform: scale(1);\n        opacity: 1;\n    }\n\n    to {\n        transform: scale(1.1);\n        opacity: 0.7;\n    }\n}\n\n@keyframes slide {\n    0% {\n        transform: translateX(100%);\n    }\n\n    100% {\n        transform: translateX(-100%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
