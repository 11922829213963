// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teamdetails_hero__Pomo6 {
  min-height: 35vh;
  width: 100%;
  padding: 3rem 4vw;
  padding-bottom: 5rem;
  padding-top: 140px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: flex-end;
}

.teamdetails_details__-fbf9 {
  display: flex;
  display: -ms-flexbox;
  gap: 24px;
  margin-top: 50px;
}

.teamdetails_hero__Pomo6 h2 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.teamdetails_hero__Pomo6 h3 {
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: var(--main-color);
}

.teamdetails_hero__Pomo6 p {
  font-size: 1.7rem;
}

.teamdetails_hero__Pomo6 img {
  height: 420px;
  max-width: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .teamdetails_details__-fbf9 {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/team-details/teamdetails.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EAEtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE;IACE,sBAAsB;EAExB;AACF","sourcesContent":[".hero {\n  min-height: 35vh;\n  width: 100%;\n  padding: 3rem 4vw;\n  padding-bottom: 5rem;\n  padding-top: 140px;\n  display: flex;\n  display: -ms-flexbox;\n  flex-direction: column;\n  -ms-flex-direction: column;\n  justify-content: flex-end;\n}\n\n.details {\n  display: flex;\n  display: -ms-flexbox;\n  gap: 24px;\n  margin-top: 50px;\n}\n\n.hero h2 {\n  font-size: 3rem;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n\n.hero h3 {\n  margin-bottom: 20px;\n  font-size: 2.5rem;\n  color: var(--main-color);\n}\n\n.hero p {\n  font-size: 1.7rem;\n}\n\n.hero img {\n  height: 420px;\n  max-width: 400px;\n  object-fit: cover;\n  object-position: top;\n  border-radius: 10px;\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  -ms-border-radius: 10px;\n  -o-border-radius: 10px;\n}\n\n@media screen and (max-width: 900px) {\n  .details {\n    flex-direction: column;\n    -ms-flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `teamdetails_hero__Pomo6`,
	"details": `teamdetails_details__-fbf9`
};
export default ___CSS_LOADER_EXPORT___;
