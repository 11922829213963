export const programDetailsEN = [
  {
    id: "pharmaceutical-technology-hnd",
    name: "Pharmaceutical Technology",
    type: "Higher National Diploma",
    heads: [
      "Higher National Diploma",
      "3 Years",
      "On Campus",
      "Full time",
      "Tuition varies",
    ],
    leftContent:
      "The pharmaceutical and cosmetics industries are among the fastest growing business sectors, globally. Accordingly, the demand for highly skilled professionals is enormous and growing. This unique undergraduate program for pharmacy technicians equips you with the knowledge and competencies you need to compete favorably on the domestic and international job markets, as well as advance your academic career.",
    rightContent: {
      list1: ["English", "Starts in October"],
      list2: ["Younde", "Douala", "Bamenda", "Ndu"],
    },
    coverImage: "",
    studySection:
      "Our curriculum for this program is designed to provide students with the fundamental understanding and skills that underpins the concepts and practice of pharmacy.  Theoretical courses and community/clinical internships give learners the opportunity to acquire the knowledge and hands-on competences necessary for drug supply chain management. Laboratory-based courses and industrial internships are designed to help students fine tune the essential technical skills required for the sourcing, preparation, and analysis of pharmaceutical agents and dosage forms. Faculty-directed undergraduate research projects provide our students with research techniques, scientific writing, and communication skills. Not only that, our students have the opportunity to develop essential soft skills that will give them the edge in the job market, such as inter and intra-professional, entrepreneurship, digital literacy, and critical thinking skills.",
    prospectusSection:
      "During your studies you work on projects with other students and solve complex issues together. You learn about your own strengths and develop your communication, team working, intercultural and leadership skills - skills that are needed in the job market.",
    careerPaths: [
      "Community and Hospital Pharmacies",
      "Pharmaceutical (manufacturing) industries",
      "Cosmetic industries",
      "Biotechnology companies",
      "Drug supply chain industries",
      "Government agencies",
      "Educational institutions",
      "And many more...",
    ],
    stafs: [
      {
        image: "",
        name: "Mme. Folibe Marinel",
        position: "Acting HOD",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Dr. Jezeh Clive",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAj0B4AVkzMs9OUU60aRe2YqgMARRjpBc-FWrM3NNpAgqwtUGp44lk27FGQ2DFKzFuVM0upVKACXc6J6OLcIxhx1mIRxQ=s1600",
        name: "Mr. Bryan Senji",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "pharmaceutical-sciences-bsc",
    name: "Pharmaceutical Sciences",
    type: "Bachelor of Science",
    heads: [
      "Bachelor of Science",
      "1 Years",
      "On Campus",
      "Full time",
      "Tuition varies",
    ],
    leftContent:
      "The demand for highly skilled professionals for the pharmaceutical industry is enormous and growing. St Louis University offers this unique undergraduate program to equips you with the knowledge and core competencies you need to compete favorably on the domestic and international job markets, as well as advance your academic career.",
    rightContent: {
      list1: ["English", "Starts in October"],
      list2: ["Younde", "Douala", "Bamenda", "Ndu"],
    },
    coverImage: "",
    studySection:
      "The curriculum employs a student-centered teaching approach to the fundamental areas of pharmaceutical science, such as pharmacognosy, medicinal chemistry, pharmaceutical microbiology, toxicology, pharmacology, pharmacovigilance, and pharmaceutical marketing. Theoretical and laboratory courses are designed to impact students with knowledge and hands-on competences in the various sciences. Laboratory courses and internships (clinical and industrial) are designed to help students hone essential technical skills for sourcing of drugs from plants and other sources, manufacturing, quality assurance, conservation, distribution, and dispensing.  Research projects directed by faculty provide our students with research techniques, scientific writing, and communication skills. Our program equally helps students to acquire teamwork, intercultural, computer literacy, and leadership skills—all of which are necessary for a successful profession.",
    prospectusSection:
      "During your studies you work on projects with other students and solve complex issues together. You learn about your own strengths and develop your communication, team working, intercultural and leadership skills - skills that are needed in the job market.",
    careerPaths: [
      "Community and Hospital Pharmacies",
      "Pharmaceutical (manufacturing) industries",
      "Cosmetic industries",
      "Food and brewery industries",
      "Biotechnology companies",
      "Drug supply chain industries",
      "Government agencies",
      "Educational institutions",
      "And many more...",
    ],
    stafs: [
      {
        image: "",
        name: "Mme. Folibe Marinel",
        position: "Acting HOD",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Dr. Jezeh Clive",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAj0B4AVkzMs9OUU60aRe2YqgMARRjpBc-FWrM3NNpAgqwtUGp44lk27FGQ2DFKzFuVM0upVKACXc6J6OLcIxhx1mIRxQ=s1600",
        name: "Mr. Bryan Senji",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "medical-imaging-technology-hnd",
    name: "Medical Imaging Technology",
    type: "Higher National Diploma",
    heads: [
      "Higher National Diploma",
      "3 Years",
      "On Campus",
      "Full time",
      "500.000 FCFA",
    ],
    leftContent:
      "Diagnostic radiography fulfill an essential role in the modern healthcare setting, using their skills and knowledge to produce detailed, high-quality anatomical and physiological images of what is happening within the human body. These images are used to assist in the diagnosis of injury and disease thereby ensuring that prompt, effective treatment is given.",
    rightContent: {
      list1: ["English", "Starts in October"],
      list2: ["Younde", "Douala", "Bamenda"],
    },
    coverImage: "",
    studySection:
      "This programme will focus various forms of imaging technology to look inside a patient's body and help diagnose injuries and illnesses. With experience, you may also contribute towards interpreting images, establishing treatment plans and helping with interventions procedures. You'll have clinical placements in each year of study within Radiology departments in our placement hospitals to gain a wide range of clinical experience while exploring all that the medical imaging modalities can offer.",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "medical-imaging-technology-bsc",
    name: "Medical Imaging Technology",
    type: "Bachelor of Sciences",
    heads: [
      "Bachelor of Sciences",
      "1 Years",
      "On Campus",
      "Full time",
      "670.000 FCFA",
    ],
    leftContent:
      "Worldwide, millions of x-rays, ultrasounds, CT and MRI scans are performed each year. Develop the skills and expertise to become a licensed radiology Technologist, with rewarding medical imaging career opportunities both in Cameroon and overseas.",
    rightContent: {
      list1: ["English", "Starts in October"],
      list2: ["Younde", "Douala", "Bamenda"],
    },
    coverImage: "",
    studySection:
      "This is Degree programs which enables students to gain expertise in several types of clinical imaging, including bone densitometry, cardiac or vascular interventional radiography, computed tomography, Magnetic Resonance Imaging, mammography, echocardiography, vascular sonography, diagnostic medical sonography, and nuclear medicine technology.",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "medical-imaging-technology-msc",
    name: "Medical Imaging Technology",
    type: "Master of Sciences",
    heads: [
      "Master of Sciences",
      "1 Years",
      "On Campus",
      "Full time",
      "902.000 FCFA",
    ],
    leftContent:
      "Deepen your specialist medical imaging knowledge, advance to a leadership position or embark on a research project with the Master of Advanced Medical Imaging Practice.",
    rightContent: {
      list1: ["English", "Starts in October"],
      list2: ["Bamenda", "Bonaberi-Douala"],
    },
    coverImage: "",
    studySection:
      "With a contemporary and evidence-based curriculum, our innovative course will keep you up to-date with advanced technologies and help you build translational skills in image analysis and processing with cardiac, MSK and neuroimaging CT and MRI, data management, health informatics and research. Gain tools to shape your future practice and develop new career pathways within your workplace.",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Lecturer",
        stafs: [
          {
            image: "",
            name: "",
            position: "",
            mail: "",
            phoneNumber: "",
          },
        ],
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Lecturer",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
];

export const programDetailsFR = [
  {
    id: "technologie-de-la-pharmacie-hnd",
    name: "Technologie de la Pharmacie",
    type: "Higher National Diploma",
    heads: [
      "Higher National Diploma",
      "3 Years",
      "Sur le campus",
      "Temps plein",
      "Frais de scolarité variables",
    ],
    leftContent:
      "Les industries pharmaceutiques et cosmétiques comptent parmi les secteurs d'activité qui connaissent la plus forte croissance au niveau mondial. En conséquence, la demande de professionnels hautement qualifiés est énorme et croissante. Ce programme unique de premier cycle pour les techniciens en pharmacie vous permet d'acquérir les connaissances et les compétences dont vous avez besoin pour être compétitif sur les marchés du travail nationaux et internationaux, ainsi que pour faire progresser votre carrière universitaire.",
    rightContent: {
      list1: ["Anglais", "Début en octobre"],
      list2: ["Youndé", "Douala", "Bamenda", "Ndu"],
    },
    coverImage: "",
    studySection:
      "Notre programme d'études est conçu pour fournir aux étudiants la compréhension et les compétences fondamentales qui sous-tendent les concepts et la pratique de la pharmacie.  Les cours théoriques et les stages communautaires/cliniques permettent aux étudiants d'acquérir les connaissances et les compétences pratiques nécessaires à la gestion de la chaîne d'approvisionnement en médicaments. Les cours en laboratoire et les stages industriels sont conçus pour aider les étudiants à affiner les compétences techniques essentielles requises pour l'approvisionnement, la préparation et l'analyse des agents pharmaceutiques et des formes de dosage. Les projets de recherche menés par les professeurs en licence permettent à nos étudiants d'acquérir des techniques de recherche, de rédaction scientifique et de communication. En outre, nos étudiants ont la possibilité de développer des compétences non techniques essentielles qui leur donneront une longueur d'avance sur le marché du travail, telles que les compétences inter et intraprofessionnelles, l'esprit d'entreprise, la culture numérique et l'esprit critique.",
    prospectusSection:
      "Pendant vos études, vous travaillez sur des projets avec d'autres étudiants et vous résolvez ensemble des problèmes complexes. Vous apprenez à connaître vos propres points forts et développez vos compétences en matière de communication, de travail en équipe, d'interculturalité et de leadership - des compétences qui sont nécessaires sur le marché du travail.",
    careerPaths: [
      "Pharmacies communautaires et hospitalières",
      "Industries pharmaceutiques (fabrication)",
      "Industries cosmétiques",
      "Sociétés de biotechnologie",
      "Industries de la chaîne d'approvisionnement en médicaments",
      "Agences gouvernementales",
      "Établissements d'enseignement",
      "Et bien d'autres encore...",
    ],
    stafs: [
      {
        image: "",
        name: "Mme. Folibe Marinel",
        position: "Directeur par intérim",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Dr. Jezeh Clive",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAj0B4AVkzMs9OUU60aRe2YqgMARRjpBc-FWrM3NNpAgqwtUGp44lk27FGQ2DFKzFuVM0upVKACXc6J6OLcIxhx1mIRxQ=s1600",
        name: "Mr. Bryan Senji",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "sciences-pharmaceutiques-bsc",
    name: "Sciences Pharmaceutiques",
    type: "Bachelor of Science",
    heads: [
      "Bachelor of Science",
      "1 Year",
      "Sur le campus",
      "Temps plein",
      "Frais de scolarité variables",
    ],
    leftContent:
      "La demande de professionnels hautement qualifiés pour l'industrie pharmaceutique est énorme et croissante. L'Université Saint-Louis propose ce programme de premier cycle unique qui vous permet d'acquérir les connaissances et les compétences essentielles dont vous avez besoin pour être compétitif sur les marchés du travail nationaux et internationaux, ainsi que pour faire progresser votre carrière universitaire.",
    rightContent: {
      list1: ["Anglais", "Début en octobre"],
      list2: ["Youndé", "Douala", "Bamenda", "Ndu"],
    },
    coverImage: "",
    studySection:
      "Le programme d'études utilise une approche pédagogique centrée sur l'étudiant dans les domaines fondamentaux de la science pharmaceutique, tels que la pharmacognosie, la chimie médicinale, la microbiologie pharmaceutique, la toxicologie, la pharmacologie, la pharmacovigilance et le marketing pharmaceutique. Les cours théoriques et de laboratoire sont conçus pour apporter aux étudiants des connaissances et des compétences pratiques dans les différentes sciences. Les cours de laboratoire et les stages (cliniques et industriels) sont conçus pour aider les étudiants à perfectionner les compétences techniques essentielles pour l'approvisionnement en médicaments à partir de plantes et d'autres sources, la fabrication, l'assurance qualité, la conservation, la distribution et la délivrance.  Les projets de recherche dirigés par les enseignants permettent aux étudiants d'acquérir des techniques de recherche, de rédaction scientifique et de communication. Notre programme aide également les étudiants à acquérir des compétences en matière de travail d'équipe, d'interculturalité, d'informatique et de leadership, qui sont toutes nécessaires à l'exercice d'une profession fructueuse.",
    prospectusSection:
      "Pendant vos études, vous travaillez sur des projets avec d'autres étudiants et vous résolvez ensemble des problèmes complexes. Vous apprenez à connaître vos propres points forts et développez vos compétences en matière de communication, de travail en équipe, d'interculturalité et de leadership - des compétences qui sont nécessaires sur le marché du travail.",
    careerPaths: [
      "Pharmacies communautaires et hospitalières",
      "Industries pharmaceutiques (fabrication)",
      "Industries cosmétiques",
      "Industries alimentaires et brassicoles",
      "Entreprises de biotechnologie",
      "Industries de la chaîne d'approvisionnement en médicaments",
      "Agences gouvernementales",
      "Établissements d'enseignement",
      "Et bien d'autres encore...",
    ],
    stafs: [
      {
        image: "",
        name: "Mme. Folibe Marinel",
        position: "Directeur par intérim",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Dr. Jezeh Clive",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAj0B4AVkzMs9OUU60aRe2YqgMARRjpBc-FWrM3NNpAgqwtUGp44lk27FGQ2DFKzFuVM0upVKACXc6J6OLcIxhx1mIRxQ=s1600",
        name: "Mr. Bryan Senji",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "technologie-de-l'imagerie-médicale-hnd",
    name: "Technologie de l'Imagerie Médicale",
    type: "Higher National Diploma",
    heads: [
      "Higher National Diploma",
      "3 Years",
      "Sur le campus",
      "Temps plein",
      "500.000 FCFA",
    ],
    leftContent:
      "La radiographie diagnostique joue un rôle essentiel dans le cadre des soins de santé modernes, en utilisant ses compétences et ses connaissances pour produire des images anatomiques et physiologiques détaillées et de haute qualité de ce qui se passe à l'intérieur du corps humain. Ces images sont utilisées pour aider au diagnostic des blessures et des maladies, garantissant ainsi un traitement rapide et efficace.",
    rightContent: {
      list1: ["Anglais", "Début en octobre"],
      list2: ["Youndé", "Douala", "Bamenda"],
    },
    coverImage: "",
    studySection:
      "Ce programme met l'accent sur diverses formes de technologie d'imagerie pour examiner l'intérieur du corps d'un patient et aider à diagnostiquer les blessures et les maladies. Avec de l'expérience, vous pourrez également contribuer à l'interprétation des images, à l'établissement de plans de traitement et à l'aide aux procédures d'intervention. Au cours de chaque année d'études, vous effectuerez des stages cliniques dans les services de radiologie de nos hôpitaux de stage afin d'acquérir une vaste expérience clinique tout en explorant tout ce que les modalités d'imagerie médicale peuvent offrir..",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "technologie-de-l'imagerie-médicale-bsc",
    name: "Technologie de l'Imagerie Médicale",
    type: "Bachelor of Sciences",
    heads: [
      "Bachelor of Sciences",
      "1 Years",
      "Sur le campus",
      "Temps plein",
      "670.000 FCFA",
    ],
    leftContent:
      "Des millions de radiographies, d'échographies, de tomographies et d'IRM sont effectuées chaque année dans le monde. Développez les compétences et l'expertise nécessaires pour devenir un technologue en radiologie agréé, avec des opportunités de carrière gratifiantes en imagerie médicale au Cameroun et à l'étranger.",
    rightContent: {
      list1: ["Anglais", "Début en octobre"],
      list2: ["Youndé", "Douala", "Bamenda"],
    },
    coverImage: "",
    studySection:
      "Il s'agit d'un programme diplômant qui permet aux étudiants d'acquérir une expertise dans plusieurs types d'imagerie clinique, notamment l'ostéodensitométrie, la radiographie interventionnelle cardiaque ou vasculaire, la tomodensitométrie, l'imagerie par résonance magnétique, la mammographie, l'échocardiographie, l'échographie vasculaire, l'échographie médicale diagnostique et la technologie de la médecine nucléaire.",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
  {
    id: "technologie-de-l'imagerie-médicale-msc",
    name: "Technologie de l'Imagerie Médicale",
    type: "Master of Sciences",
    heads: [
      "Master of Sciences",
      "1 Years",
      "Sur le campus",
      "Temps plein",
      "902.000 FCFA",
    ],
    leftContent:
      "Approfondissez vos connaissances spécialisées en imagerie médicale, accédez à un poste de direction ou lancez-vous dans un projet de recherche avec le Master of Advanced Medical Imaging Practice.",
    rightContent: {
      list1: ["Anglais", "Début en octobre"],
      list2: ["Bamenda", "Bonaberi-Douala"],
    },
    coverImage: "",
    studySection:
      "Grâce à un programme d'études contemporain et fondé sur des données probantes, notre cours innovant vous permettra de rester au fait des technologies de pointe et vous aidera à acquérir des compétences translationnelles en matière d'analyse et de traitement d'images avec la tomodensitométrie et l'IRM cardiaques, MSK et neuro-imagerie, de gestion des données, d'informatique de la santé et de recherche. Acquérir des outils pour façonner votre pratique future et développer de nouvelles voies de carrière sur votre lieu de travail.",
    stafs: [
      {
        image: "",
        name: "Mr. Ngek Wilson Chongwam",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image: "",
        name: "Mr. Ngouopayo Dalhad",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCcTgOZChtmHLCihHmu9a-CoAKUKGEM8nTMqf1tFIgo3Klpv_mxMfvrWzMe1O_ukcLLXGguiPvuZ72A08T9qfmKnj9Y=s1600",
        name: "Mr. Nfor Clauvis S.",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
      {
        image:
          "https://lh3.googleusercontent.com/drive-viewer/AK7aPaAU_kJYlnUxEYye7YMoG_XqD8t13Hiu1Bfc8RxCiGQJNVHf3YptO4KO_XC0CCsKJZXKIn16XuqpF18qn6imRmHQOSc28w=s1600",
        name: "Mr. Kabila Foncha",
        position: "Maître de conférences",
        mail: "",
        phoneNumber: "",
      },
    ],
  },
];
