// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/optimized/ivs-7478.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-us_hero__zQB3k {
    min-height: 85vh;
    width: 100%;
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
      url("../../assets/landing.webp"), no-repeat; */
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
      url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
    background-position: top;
    background-size: cover;
    padding: 3rem 4vw;
    padding-bottom: 5rem;
    margin-top: 150px;
     display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 1012px) {
    .why-us_hero__zQB3k {
      padding-top: 70rem;
    }
  }
  
  .why-us_hero__zQB3k h3 {
    font-size: 2rem;
    color: #fff;
  }
  
  .why-us_hero__zQB3k h1 {
    font-size: 7rem;
    color: #fff;
    font-weight: 700;
  }
  
  .why-us_desc__xlmZ8 {
      padding: 4rem 4vw;
  }
  
  .why-us_desc__xlmZ8 .why-us_head__d0w3- h2 {
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 2rem;
      color: var(--main-color);
  }
  
  .why-us_desc__xlmZ8 .why-us_content__wfXzj h4 {
      font-size: 2rem;
      line-height: 5rem;
      margin-bottom: 1rem;
      text-align: justify;
  }`, "",{"version":3,"sources":["webpack://./src/pages/why-slui/why-us.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX;oDACgD;IAChD;wDACwD;IACxD,wBAAwB;IACxB,sBAAsB;IACtB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;KAChB,aAAa;IACd,oBAAoB;IACpB,sBAAsB;IAEtB,yBAAyB;EAC3B;;EAEA;IACE;MACE,kBAAkB;IACpB;EACF;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;;EAEA;MACI,iBAAiB;EACrB;;EAEA;MACI,eAAe;MACf,gBAAgB;MAChB,mBAAmB;MACnB,wBAAwB;EAC5B;;EAEA;MACI,eAAe;MACf,iBAAiB;MACjB,mBAAmB;MACnB,mBAAmB;EACvB","sourcesContent":[".hero {\n    min-height: 85vh;\n    width: 100%;\n    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),\n      url(\"../../assets/landing.webp\"), no-repeat; */\n    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),\n      url(\"../../assets/optimized/ivs-7478.webp\"), no-repeat;\n    background-position: top;\n    background-size: cover;\n    padding: 3rem 4vw;\n    padding-bottom: 5rem;\n    margin-top: 150px;\n     display: flex;\n    display: -ms-flexbox;\n    flex-direction: column;\n    -ms-flex-direction: column;\n    justify-content: flex-end;\n  }\n  \n  @media screen and (max-width: 1012px) {\n    .hero {\n      padding-top: 70rem;\n    }\n  }\n  \n  .hero h3 {\n    font-size: 2rem;\n    color: #fff;\n  }\n  \n  .hero h1 {\n    font-size: 7rem;\n    color: #fff;\n    font-weight: 700;\n  }\n  \n  .desc {\n      padding: 4rem 4vw;\n  }\n  \n  .desc .head h2 {\n      font-size: 4rem;\n      font-weight: 600;\n      margin-bottom: 2rem;\n      color: var(--main-color);\n  }\n  \n  .desc .content h4 {\n      font-size: 2rem;\n      line-height: 5rem;\n      margin-bottom: 1rem;\n      text-align: justify;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `why-us_hero__zQB3k`,
	"desc": `why-us_desc__xlmZ8`,
	"head": `why-us_head__d0w3-`,
	"content": `why-us_content__wfXzj`
};
export default ___CSS_LOADER_EXPORT___;
